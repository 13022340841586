import { initStore } from './store';

import energie from '../assets/images/stock-photos/energie.jpg';
import earth2 from '../assets/images/stock-photos/earth2.jpg';
import avatar1 from '../assets/images/avatars/avatar1.jpg';
import avatar2 from '../assets/images/avatars/avatar2.jpg';
import avatar3 from '../assets/images/avatars/avatar3.jpg';

import sad from '../assets/images/stock-photos/sad.jpg';
import supportSmalImage from '../assets/images/illustrations/tree_swing.svg';
import cleanup from '../assets/images/stock-photos/planet-earth-first.jpg';
import comunity from '../assets/images/stock-photos/community2_800x500.jpg';
import bike from '../assets/images/stock-photos/bike2_800x500.jpg';
import bike2 from '../assets/images/stock-photos/bike.jpg';
import veggi from '../assets/images/stock-photos/veggi.jpg';
import supportBigImage from '../assets/images/stock-photos/success4_500x620.jpg';
import SmoothPandaPopapier from '../assets/images/stock-photos/SmoothPanda_Toilettenpapier_800x500.jpg';
import WeNeedYou from '../assets/images/stock-photos/Fotolia_44430275_800x500.jpg';
import autumn from '../assets/images/stock-photos/autumn_800x500.jpg';
import team from '../assets/images/stock-photos/Fotolia_Team_800x500.jpg';
import drive from '../assets/images/stock-photos/driving_800_500.jpg';
import gloves from '../assets/images/stock-photos/gloves_800_500.jpg';
import adac from '../assets/images/stock-photos/adac.jpg';
import EnergieScouts from '../assets/images/stock-photos/Energie-Scouts_800x500.jpg';
import mac from '../assets/images/stock-photos/mac-800_500.jpg';

const configureStore = () => {
  const actions = {
    BUY_ACTION: (curState, payload) => {
      console.log('BUY_ACTION ' + JSON.stringify(payload));
      const actionIndex = curState.actions.findIndex(a => a.id === payload.id);
      const updatedActions = [...curState.actions];
      updatedActions[actionIndex] = {
        ...curState.actions[actionIndex],
        actionState: 'available',
        new: true
      };
      return { ...curState, actions: updatedActions };
    }
  };

  initStore(actions, {
    actions: [
      {
        id: 1,
        title: 'Lorem impsum', //title: 'Energiescouts',
        actionState: 'available',
        new: false,
        imageSrc: energie,
        teaserText:
          'Werde Energiescout, erkenne Potentiale und finde Lösungen! In einem zweitägigen Workshop machen wir dich in den Themenfeldern Energie- und Ressourceneffizienz fit. Du lernst, mit offenen Augen durch den Betrieb zu gehen, verschwenderischen Umgang mit Ressourcen zu erkennen und dich für die Verbesserung einzusetzen. ',
        detailText:
          'Du leistest einen aktiven Beitrag zum Klimaschutz, sensibilisierst deine Kolleginnen und Kollegen und bist Botschafter im Unternehmen. Weitere Informationen zu den Ausbildungsinhalten findest Du im Intranet.',
        detailLink: 'https://de.wikipedia.org/wiki/Nachhaltigkeit',
        points: 12,
        marketplaceCosts: 520,
        start: new Date('07.10.2020'),
        end: new Date('08.10.2020'),
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        topics: ['Energie'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar2, co2Savings: 4 },
          { id: '5', alt: 'alias', imageSrc: avatar3, co2Savings: 1 },
          { id: '6', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '7', alt: 'alias', imageSrc: avatar3, co2Savings: 5 },
          { id: '8', alt: 'alias', imageSrc: avatar1, co2Savings: 2 },
          { id: '9', alt: 'alias', imageSrc: avatar2, co2Savings: 5 }
        ],
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 2,
        title: 'Lorem impsum', //title: 'Earth Hour',
        new: false,
        actionState: 'available',
        imageSrc: earth2,
        teaserText:
          'Schalte um genau 20.30 Uhr für eine Stunde das Licht aus und setzte so ein gemeinsames Zeichen für unseren lebendigen Planeten. ',
        detailText:
          'Viele Menschen auf der Welt machen mit und werden so Teil der größten Umweltschutzaktion der Welt - einfach von Zuhause aus! Mach mit, schalte das Licht aus und poste ein Foto.',
        points: 3,
        marketplaceCosts: 520,
        topics: ['Energie'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        start: new Date('03.27.2020'),
        end: new Date('03.27.2020'),
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar2, co2Savings: 4 },
          { id: '5', alt: 'alias', imageSrc: avatar3, co2Savings: 1 },
          { id: '6', alt: 'alias', imageSrc: avatar2, co2Savings: 5 }
        ],
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 3,
        title: 'Lorem impsum', //title: 'Wir helfen in Zeiten von Corona.',
        imageSrc: comunity,
        teaserText:
          'Jeder Beitrag zählt in Zeiten der Corona-Krise. Melde dich bei uns und unterstütze Senioren und Menschen, die zur Risikogruppe gehören und sich in Quarantäne befinden in deiner Nähe! Durch kleinen Botengängen, das Erledigen von Einkäufen oder den Hund eine Runde Gassi führen, kannst du Gutes tun. Für jede gute Tat bekommst Du Punkte, die Du wieder in Projekte einzahlen kannst.',
        detailText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
        detailLink: 'https://de.wikipedia.org/wiki/Nachhaltigkeit',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 3,
        marketplaceCosts: 520,
        actionState: 'available',
        topics: ['Soziales'],
        co2SavingPerPerson: 0.1,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar1, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 4,
        title: 'Lorem impsum', //title: 'Bike Challenge',
        new: false,
        imageSrc: bike,
        teaserText:
          'Jeder (CO2-freie) Kilometer zählt!. Ein Monat – so viele Kilometer wie möglich. Trete in die Pedale für mehr Klimaschutz. Gemeinsam im Team battelst du dich mit anderen.',
        detailText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
        detailLink: 'https://de.wikipedia.org/wiki/Nachhaltigkeit',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 5,
        marketplaceCosts: 520,
        actionState: 'available',
        start: new Date('10.06.2020'),
        end: new Date('10.07.2020'),
        topics: ['Klima'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar3, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar2, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar1, co2Savings: 4 },
          { id: '5', alt: 'alias', imageSrc: avatar3, co2Savings: 1 },
          { id: '6', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '7', alt: 'alias', imageSrc: avatar3, co2Savings: 5 },
          { id: '8', alt: 'alias', imageSrc: avatar1, co2Savings: 2 },
          { id: '9', alt: 'alias', imageSrc: avatar2, co2Savings: 5 }
        ],
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 5,
        title: 'Lorem impsum', //title: 'VEGGIE Challenge',
        new: false,
        imageSrc: veggi,
        actionState: 'available',
        teaserText:
          'Eine Woche auf Fleisch zu verzichten ist nicht schwer, oder?! Mach mit und probiere es wortwörtlich aus! Rund ein Fünftel der Treibhausgas-Emissionen entstehen durch unsere Ernährung.',
        detailText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
        detailLink: 'https://de.wikipedia.org/wiki/Nachhaltigkeit',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 4,
        marketplaceCosts: 520,
        topics: ['Energie', 'Wasser', 'Klima'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar2, co2Savings: 4 },
          { id: '5', alt: 'alias', imageSrc: avatar3, co2Savings: 1 },
          { id: '6', alt: 'alias', imageSrc: avatar1, co2Savings: 2 },
          { id: '7', alt: 'alias', imageSrc: avatar2, co2Savings: 5 },
          { id: '8', alt: 'alias', imageSrc: avatar3, co2Savings: 2 },
          { id: '9', alt: 'alias', imageSrc: avatar2, co2Savings: 5 }
        ],
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 6,
        title: 'Lorem impsum', //title: 'City Clean up',
        new: false,
        imageSrc: cleanup,
        actionState: 'available',
        teaserText:
          'Damit unsere Stadt sauberer wird, beteiligen wir uns bei der Aktionswoche CITY CLEAN UP. Melde dich mit Kollegen oder deiner Familie an einem der Aktionstage (22.09.- 29.09.) an und pack‘ mit an!',
        detailText: 'Lorem ipsum dolor sit amet, consetetur sadipscing elitr',
        detailLink: 'https://de.wikipedia.org/wiki/Nachhaltigkeit',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 12,
        marketplaceCosts: 520,
        start: new Date('09.22.2020'),
        end: new Date('09.29.2020'),
        topics: ['Soziales'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar2, co2Savings: 4 },
          { id: '5', alt: 'alias', imageSrc: avatar3, co2Savings: 1 },
          { id: '6', alt: 'alias', imageSrc: avatar1, co2Savings: 2 },
          { id: '7', alt: 'alias', imageSrc: avatar2, co2Savings: 5 },
          { id: '8', alt: 'alias', imageSrc: avatar3, co2Savings: 2 },
          { id: '9', alt: 'alias', imageSrc: avatar2, co2Savings: 5 }
        ],
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 7,
        title: 'Lorem impsum', //title: 'Smooth Panda Popapier',
        new: false,
        teaserText:
          ' Auf der Toilette schonmal über Nachhaltigkeit nachgedacht ... Als erster deutscher Hersteller stellt Smooth Panda Hygienepapiere aus 100% Bambus her. So erfolgreich, dass bereits GALILEO von Pro7 berichtet hat.',
        detailText: 'Erfahre mehr über Nachhaltigkeit am stillen Örtchen!',
        detailLink: 'https://smoothpanda.de/videos/',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 120,
        marketplaceCosts: 520,
        topics: ['Wasser', 'Klima'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar3, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar1, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        imageSrc: SmoothPandaPopapier,
        actionState: 'marketplace',
        dialogMarketplace: {
          bigImage: SmoothPandaPopapier,
          smalImage: SmoothPandaPopapier,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Warum eigentlich Bambus? Im Gegensatz zu Bäumen, stirbt Bambus nach dem Schlagen nicht ab. Botanisch betrachtet gehört Bambus zur Familie der Gräser und wie jeder weiß, wächst Rasen nach dem Mähen wieder nach. So ist es auch bei der Bambuspflanze. Auch enthält Bambus mehr Zellstoff als Holz und benötigt daher weniger Anbaufläche. Nicht nur der Rohstoff, auch die Produkte sind tierfreundlich und vollkommen vegan.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        }
      },
      {
        id: 8,
        title: 'Werde Teil des Green-Gremiums!',
        new: false,
        teaserText:
          'Sei an der vordersten Front dabei! Unterstütze tatkräftig bei der Umsetzung der Projekte und Aktionen, finde Wege die Community zu aktivieren und Entscheidungsvorlagen vorzubereiten. Das Gremium trifft sich alle 14 Tage, um die anstehenden Aufgaben zu organisieren.',
        detailText:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
        detailLink: 'https://www.youtube.com/watch?v=qJYlIN6VwS4&t=5s',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 35,
        start: new Date('8.1.2020'),
        end: new Date('10.31.2020'),
        maxPeople: 4,
        marketplaceCosts: 340,
        topics: ['Soziales'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 3 },
          { id: '2', alt: 'alias', imageSrc: avatar2, co2Savings: 2 },
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar1, co2Savings: 4 }
        ],
        imageSrc: WeNeedYou,
        actionState: 'support',
        dialogMarketplace: {
          bigImage: SmoothPandaPopapier,
          smalImage: SmoothPandaPopapier,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Bei der Aktione mithelfen',
          subheading: 'Spitze, dass Du dabei bist!',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 9,
        title: 'Wie können wir Grauwasser am Standort Gelmer nutzen?',
        new: false,
        teaserText:
          'Durch die Grauwassernutzung können durchschnittlich bis zu 50 % Trink- und Abwasserkosten können eingespart werden. Grauwasser entsteht überall dort, wo Wasser beim Duschen, Baden oder Händewaschen nur leicht verschmutzt wird. Es kann mit einfachen Mitteln vor Ort auf Betriebswasserqualität gebracht werden.',
        detailText:
          'Finde in einem Team heraus, welche Möglichkeiten es am Standort Gelmer gibt, Grauwasser zu nutzen. Was bringt uns das und welche Investitionshöhe wird benötigt?Die Idee wurde von Max Mustermann eingereicht.',
        detailLink: 'https://www.youtube.com/watch?v=qJYlIN6VwS4&t=5s',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 25,
        maxPeople: 5,
        marketplaceCosts: 340,
        topics: ['Wasser'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar2, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar1, co2Savings: 4 }
        ],
        imageSrc: autumn,
        actionState: 'support',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Toll, dass Du dabei bist.',
          subheading: 'Wasser marsch…',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 10,
        title:
          'Organisiere den Gesundheitstag unter dem Motto „Stress und Entspannung“',
        new: false,
        teaserText:
          'Du bist ein Organisationstalent? Du packst gerne mit an und hast Spaß eine interne Veranstaltung zu organisieren? Dann melde dich an! Der Gesundheitstag soll von und für die Kollegen sein, über aktuelle Gesundheitsthemen aufklären und für einen Aha!-Effekt sorgen.',
        detailText:
          'Dieses Jahr steht der Gesundheitstag unter dem Motto „Stress und Entspannung“. Wie der Tag gestaltet wird, entscheidet das ihr.',
        detailLink: 'https://www.youtube.com/watch?v=qJYlIN6VwS4&t=5s',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 25,
        maxPeople: 7,
        marketplaceCosts: 340,
        topics: ['Energie', 'Wasser', 'Klima'],
        start: new Date('9.20.2020'),
        end: new Date('9.20.2020'),
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar2, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar1, co2Savings: 4 }
        ],
        imageSrc: team,
        actionState: 'support',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Spitze, dass Du dabei bist!',
          subheading: 'Das wird ein super Event. ',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 11,
        title: 'Wie können wir smart Fahrgemeinschaften bilden?',
        new: true,
        teaserText:
          '10% der Mitarbeiter aus der Zentrale kommen aus Dortmund und jeder fährt mit seinem eigenen Auto? Das geht doch auch anders, oder?',
        detailText:
          'Wie kann eine smarte Lösung für Fahrgemeinschaften aussehen, wie können wir informieren und unterschiedliche Arbeitszeiten berücksichtigen? Über welches Tool bilden wir das ab? Mach mit und überlege dir Antworten auf all diese Fragen.',
        detailLink: 'https://www.youtube.com/watch?v=qJYlIN6VwS4&t=5s',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 20,
        maxPeople: 2,
        marketplaceCosts: 340,
        topics: ['Mobilität'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar3, co2Savings: 6 }
        ],
        imageSrc: drive,
        actionState: 'support',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Spitze, dass Du dabei bist!',
          subheading: 'Auf die Plätze, fertig, los… ',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt aktivieren'
        }
      },
      {
        id: 12,
        title:
          'Finde eine umweltfreundliche Alternative zum Plastikhandschuh an Tankstellen?',
        new: true,
        teaserText:
          'An allen Westfalen-Tankstellen werden bis zu 15.000 Plastikhandschuhe in einem Monat verwendet. Gibt es keine umweltfreundliche Alternative? ',
        detailText:
          'In einem Team analysiert ihr zunächst die Ist-Situation: Wofür werden die Handschuhe gebraucht? Welche Gründe gibt es für die Verwendung? Fahrt Vor-Ort zu den Tankstellen und schaut euch die Gegebenheiten an. Anschließend wird die Frage beantwortet: Wie können wir den Verbrauch reduzieren oder gibt es eine Alternative? Wie viel Ressourcen sparen wir und wie hoch ist der Invest? Ein spannendes Thema. Hast Du Lust dabei zu sein?',
        detailLink: 'https://www.youtube.com/watch?v=qJYlIN6VwS4&t=5s',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 50,
        maxPeople: 5,
        marketplaceCosts: 340,
        topics: ['Umwelt'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar1, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        imageSrc: gloves,
        actionState: 'support',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Bei der Aktione mithelfen',
          subheading: 'Spitze, dass Du dabei bist!',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 13,
        title: 'Mit dem Rad zur Arbeit',
        new: false,
        teaserText:
          'Bewegung macht Spaß, fit und gesund. Das merken Berufstätige jedes Jahr bei der bundesweiten Aktion „Mit dem Rad zur Arbeit“ von ADFC und der Gesundheitskasse AOK. Sie läuft vom 1. Mai bis zum 31. August.',
        detailText:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ',
        detailLink: 'https://youtu.be/FBHCyzlWQKc ',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 50,
        maxPeople: 5,
        start: new Date('5.1.2020'),
        end: new Date('8.31.2020'),
        marketplaceCosts: 120,
        topics: ['Mobilität'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar1, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar2, co2Savings: 4 },
          { id: '2', alt: 'alias', imageSrc: avatar3, co2Savings: 4 }
        ],
        imageSrc: bike2,
        actionState: 'marketplace',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text: 'Finde dich in dem Team zusammen und bearbeite das Thema.',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text: 'Finde dich in dem Team zusammen und bearbeite das Thema',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Bei der Aktione mithelfen',
          subheading: 'Spitze, dass Du dabei bist!',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 14,
        title: 'Fahrradfreundlicher Arbeitgeber',
        new: false,
        teaserText:
          'Immer mehr Unternehmen setzen auf Fahrradfreundlichkeit. Nutzen auch Sie die Angebote des ADFC: Erhöhen Sie Ihre Attraktivität als Arbeitgeber, tragen Sie zur Mitarbeitergesundheit und zum Umweltschutz bei und senken Sie Ihre Kosten.',
        detailText:
          'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.',
        detailLink:
          'https://www.adfc.de/artikel/fahrradfreundlicher-arbeitgeber/',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 50,
        maxPeople: 5,
        marketplaceCosts: 680,
        topics: ['Mobilität'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar2, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar3, co2Savings: 4 },
          { id: '1', alt: 'alias', imageSrc: avatar1, co2Savings: 4 }
        ],
        imageSrc: adac,
        actionState: 'marketplace',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Bei der Aktione mithelfen',
          subheading: 'Spitze, dass Du dabei bist!',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 15,
        title: 'Suchmaschine umstellen',
        new: false,
        teaserText:
          'Lassen Sie ihre Mitarbeiter auf einen klimafreundlichen Suchanbieter umstellen und so mit jeder Suchanfrage Bäume pflanzen.',
        detailText:
          'Hier bekommen Sie alles Wissenswerte und eine Schritt-für-Schritt-Anleitung zur Umsetzung bei Ihnen im Unternehmen.',
        detailLink: 'https://www.youtube.com/watch?v=qJYlIN6VwS4&t=5s',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 50,
        maxPeople: 5,
        marketplaceCosts: 0,
        topics: ['Energie'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar2, co2Savings: 6 },
          { id: '4', alt: 'alias', imageSrc: avatar1, co2Savings: 4 }
        ],
        imageSrc: mac,
        actionState: 'marketplace',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text: 'Finde dich in dem Team zusammen und bearbeite das Thema',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text: 'Finde dich in dem Team zusammen und bearbeite das Thema',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Bei der Aktione mithelfen',
          subheading: 'Spitze, dass Du dabei bist!',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      },
      {
        id: 16,
        title: 'Energiescouts',
        new: false,
        teaserText:
          'Jungen Menschen Verantwortung übertragen, sie an die Projektarbeit heranführen, direkt Erfolgserlebnisse ermöglichen und bei alledem auch noch Effizienzpotenziale im Betrieb aufzudecken und zu heben: So lässt sich das Projekt EnergieScouts kurz und knapp beschreiben.',
        detailText:
          'Mithilfe dieses Wissens sollen die jungen Mitarbeiter nun als "Energie-Scouts" in ihren Unternehmen helfen, Energieeinsparpotenziale zu erkennen, Ideen zu verfolgen und Verbesserungen anzuregen.',
        detailLink:
          'https://www.mittelstand-energiewende.de/unsere-angebote/energie-scouts-qualifizierung-fuer-azubis.html',
        feedbackText: 'Hast du die Aufgabe heute erledigt?',
        points: 50,
        maxPeople: 5,
        marketplaceCosts: 340,
        topics: ['Energie'],
        co2SavingPerPerson: 0.8,
        treeFactor: 0.111,
        activeUser: [
          { id: '3', alt: 'alias', imageSrc: avatar2, co2Savings: 6 }
        ],
        imageSrc: EnergieScouts,
        actionState: 'marketplace',
        dialogMarketplace: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Aktion Übernehmen',
          subheading:
            'Willst du diese Aktion für dein Unternehmen auch zur Verfügung stellen?',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogJoin: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Los gehts!',
          subheading: 'Jede Reise beginnt mit dem ersten Schritt',
          text:
            'Finde dich in dem Team zusammen und bearbeite das Thema „Wie können wir Grauwasser am Standort Gelmer nutzen?',
          buttonLabel: 'Jetzt aktivieren'
        },
        dialogLeave: {
          bigImage: sad,
          smalImage: supportSmalImage,
          heading: 'Wirklich abmelden?',
          subheading: 'Versuche doch noch einen Tag mehr ;-)',
          text:
            'Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. ',
          buttonLabel: 'Wirklich abmelden'
        },
        dialogSupport: {
          bigImage: supportBigImage,
          smalImage: supportSmalImage,
          heading: 'Bei der Aktione mithelfen',
          subheading: 'Spitze, dass Du dabei bist!',
          text:
            'Deine erarbeiteten Punkte kannst Du anschließend auf die Projekte investieren.',
          buttonLabel: 'Jetzt mithelfen'
        }
      }
    ]
  });
};

export default configureStore;
